/* eslint-disable no-unused-vars */
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import BuildIcon from '@mui/icons-material/Build';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Card, Divider, Grid, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useFieldArray, useForm } from 'react-hook-form';

import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import moment from 'moment';
import ButtonWithProgress from '../../components/ButtonWithProgress.jsx';
import EditForm from '../../components/EditForm/EditForm.jsx';
import EditFormAction from '../../components/EditForm/EditFormAction.jsx';
import EditFormContent from '../../components/EditForm/EditFormContent.jsx';
import EditFormField from '../../components/EditForm/EditFormField.jsx';
import EditFormFooter from '../../components/EditForm/EditFormFooter.jsx';
import EditFormHeader from '../../components/EditForm/EditFormHeader.jsx';
import EditFormMessage from '../../components/EditForm/EditFormMessage.jsx';
import TagField from '../../components/EditForm/TagField';
import firebase from '../../helpers/firebase';
import { formatValue } from '../../helpers/formatter';
import { validateField } from '../../helpers/validations';
import useCloseConfirm, {
  CONFIRM_RESULT,
} from '../../hooks/useCloseConfirm.jsx';
import useConfirm from '../../hooks/useConfirm.jsx';
import { appActions } from '../app/app-slice';
import CustomField from '../journeys/CustomField';
import { monitorActions } from '../monitor/monitor-slice.js';
import SmartTip from '../smart-tip/SmartTip.jsx';
import { customersActions } from './customers-slice.js';

const useStyles = makeStyles((theme) => ({
  clsSecret: {
    backgroundColor: 'black',
    color: 'red',
  },
  clsSecretActive: {
    color: theme.palette.secondary.main,
  },
  '@keyframes blinker': {
    ['0%, 90%']: { scale: 1 },
    ['45%']: { scale: 1.25 },
  },
}));

const ChangePhoneMessage = (
  <>
    Atenção!
    <br />
    <br />
    Se você alterar o telefone do cliente, o agente virtual irá precisar
    confirmar novamente a identidade do cliente.
    <br />
    <br />
    Deseja alterar mesmo assim ?
  </>
);

function EditCustomer({ onClose }) {
  const { clsSecretActive, clsSecret } = useStyles();
  const [ChangePhoneConfirmationDialog, confirmChangePhone] = useConfirm(
    'Alteração de telefone',
    ChangePhoneMessage
  );
  const [CloseConfirmDialog, confirmClose] = useCloseConfirm();

  const { permissions } = useSelector((state) => state.auth);

  const { isLoading, isSecretActive } = useSelector((state) => state.app);
  const { selected, selectedContext, metadata, selectedCurrentJourney } =
    useSelector((state) => state).customers;
  const {
    enterprise: { id: eid, customersTypes, phoneNumber } = {},
    pixAccount,
  } = useSelector((state) => state.settings);
  const invoices = selected.invoices
    ? selected.invoices.map((i) => {
        return {
          ...i,
          notificationPhones: i.notificationPhones
            ? i.notificationPhones.replace(/#/g, ',')
            : undefined,
        };
      })
    : [{}];
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    setError,
  } = useForm({
    defaultValues: {
      name: selected.name ?? '',
      phone: selected.phone ? formatValue(selected.phone, 'phone') : '',
      customerDocNumber: selected.customerDocNumber
        ? formatValue(selected.customerDocNumber, 'docNumber')
        : '',
      cpcStatus: selected.cpcStatus ?? 'CPC_INCOMPLETE',
      invoices: invoices.map(({ docNumber, ...other }) => ({
        docNumber: docNumber ? formatValue(docNumber, 'docNumber') : '',
        ...other,
      })),
      tags: selected.tags ? selected.tags.map((t) => ({ name: t })) : [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'invoices',
  });

  console.log('errors: ', errors);

  const dispatch = useDispatch();

  const onSubmit = (formData) => {
    // console.log(formData);
    try {
      let valid = true;
      const invoicesToSave = formData.invoices.map((invoice, index) => {
        if (
          invoice.invoiceValue !== undefined &&
          invoice.invoiceDiscount !== undefined
        ) {
          if (invoice.invoiceValue - invoice.invoiceDiscount <= 0) {
            valid = false;
            setError(`invoices.${index}.invoiceValue`, {
              type: 'manual',
              message:
                'O valor da fatura não pode ser igual ou menor o valor do desconto',
            });
            setError(`invoices.${index}.invoiceDiscount`, {
              type: 'manual',
              message:
                'O valor da fatura não pode ser igual ou menor o valor do desconto',
            });
          }
        }
        if (invoice.invoiceDay !== undefined) {
          if (invoice.invoiceDay < 1 || invoice.invoiceDay > 31) {
            valid = false;
            setError(`invoices.${index}.invoiceDay`, {
              type: 'manual',
              message: 'O dia do vencimento precisa ser entre 1 e 31',
            });
          }
        }
        if (invoice.invoiceOverdue !== undefined) {
          if (invoice.invoiceOverdue < 0 || invoice.invoiceOverdue > 20) {
            valid = false;
            setError(`invoices.${index}.invoiceOverdue`, {
              type: 'manual',
              message: 'O valor da multa precisa ser entre 0 e 20',
            });
          }
        }
        let notificationPhonesTransformed = invoice.notificationPhones
          ? invoice.notificationPhones.trim()
          : undefined;

        if (
          invoice.notificationPhones !== undefined &&
          invoice.notificationPhones.trim() !== ''
        ) {
          const { sanitizedValue: arrPhones } = validateField(
            invoice.notificationPhones,
            { type: 'phoneList' }
          );
          if (arrPhones.length > 3) {
            valid = false;
            setError(`invoices.${index}.notificationPhones`, {
              type: 'manual',
              message: 'Você só pode colocar até 3 telefones de notificação.',
            });
          }
          notificationPhonesTransformed = arrPhones.join('#');
        }
        return {
          ...invoice,
          notificationPhones: notificationPhonesTransformed,
          docNumber: invoice.docNumber.match(/\d+/g).join(''),
          invoiceId: invoice.invoiceId.trim(),
        };
      });

      let resetCpc = {};
      if (dirtyFields.phone)
        resetCpc = {
          cpcAt: firebase.firestore.FieldValue.delete(),
          cpcStatus: firebase.firestore.FieldValue.delete(),
        };

      let updateCpc = {};
      if (selected.cpcStatus !== formData.cpcStatus) {
        if (formData.cpcStatus === 'CPC_SUCCESS')
          updateCpc = {
            cpcAt: Date.now(),
            cpcStatus: 'CPC_SUCCESS',
            trainedAt: Date.now(),
            trainingStatus: 'TRAINING_COMPLETE',
            firstContactAt: Date.now(),
          };
        else {
          updateCpc = {
            cpcAt: firebase.firestore.FieldValue.delete(),
            cpcStatus: formData.cpcStatus,
            trainedAt: firebase.firestore.FieldValue.delete(),
            trainingStatus: firebase.firestore.FieldValue.delete(),
            firstContactAt: firebase.firestore.FieldValue.delete(),
          };
        }
      }

      if (valid) {
        const { id } = selected;
        const dataToSave = {
          id,
          ...formData,
          phone: formData.phone.match(/\d+/g).join(''),
          customerDocNumber: formData.customerDocNumber.match(/\d+/g).join(''),
          invoices: invoicesToSave,
          tags: [...formData.tags.map(({ name }) => name)].sort(),
        };

        if (selectedContext === 'edit')
          dispatch(
            customersActions.save(
              _.omitBy({ ...dataToSave, ...resetCpc, ...updateCpc }, _.isNaN)
            )
          );
        else if (selectedContext === 'new')
          dispatch(customersActions.add(_.omitBy(dataToSave, _.isNaN)));
      } else {
        // console.log('Invalid fields');
      }

      //console.log(dataToSave);
      // if (selectedContext === 'edit')
      //   dispatch(customersActions.save(_.omitBy(dataToSave, _.isNaN)));
      // else if (selectedContext === 'new')
      //   dispatch(customersActions.add(_.omitBy(dataToSave, _.isNaN)));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const handleCloseClick = async () => {
    if (!isDirty) onClose(false);
    else {
      const result = await confirmClose();
      switch (result) {
        case CONFIRM_RESULT.EXIT:
          onClose(false);
          break;
        case CONFIRM_RESULT.CONFIRM:
          handleSaveClick();
          break;
        case CONFIRM_RESULT.CANCEL:
        default:
      }
    }
  };

  const handleSaveClick = async () => {
    // console.log('handleSaveClick');
    if (selectedContext === 'edit' && dirtyFields.phone) {
      const answer = await confirmChangePhone();
      if (answer) handleSubmit(onSubmit)();
    } else handleSubmit(onSubmit)();
  };

  const additionalFields = [
    {
      key: 'docNumber',
      name: 'docNumber',
      label: 'CPF ou CNPJ (da fatura)',
      required: true,
      type: 'text',
      showOrder: 0,
      rules: {
        required: 'Obrigatório',
        validate: (value) => {
          const { valid, error } = validateField(value, { type: 'docNumber' });
          return valid || error;
          //testCPF(value) || testCNPJ(value) || 'CPF ou CNPJ inválido',
        },
        minLength: {
          value: 4,
          message: 'Digite um CPF ou CPNJ válido',
        },
      },
    },
    ...(Object.keys(metadata).length > 0 &&
    customersTypes &&
    customersTypes.length > 0
      ? _.uniq(
          customersTypes
            .map((id) => {
              const props = metadata[id].properties;
              const keys = Object.keys(props);
              return keys.map((key) => ({
                key,
                name: key,
                ...props[key],
                rules: {
                  //required: `O campo ${props[key].csvColumn} é obrigatório`,
                  validate: (value) => {
                    const { valid, error } = validateField(value, props[key]);
                    return valid || error;
                    //testCPF(value) || testCNPJ(value) || 'CPF ou CNPJ inválido',
                  },
                  valueAsNumber:
                    props[key].type === 'decimal' ||
                    props[key].type === 'integer',
                  valueAsBoolean: props[key].type === 'boolean',
                  pattern:
                    props[key].type === 'integer'
                      ? {
                          value: /^[0-9]*$/,
                          message: 'Digite apenas números',
                        }
                      : undefined,
                  //validate: (v) => validate(v, props[key]),
                },
              }));
            })
            .flat()
            .sort((a, b) => a.showOrder - b.showOrder)
        )
      : []),
  ];
  // console.log(additionalFields);
  // console.log(selected);
  // console.log(fields);
  // console.log(errors);
  // const invoices = selected.invoices ?? [{}];

  const handleShowJourneyClick = () => {
    const { pix } = selectedCurrentJourney;
    dispatch(monitorActions.setSelected({ pix, isDetailOpen: false }));
    dispatch(
      monitorActions.loadJourneys({
        customerDocNumber: pix.invoiceData.customerData.customerDocNumber,
        jid: pix.jid,
      })
    );
  };

  return (
    <EditForm>
      <EditFormHeader
        title="Cliente"
        onCloseClick={handleCloseClick}
        actions={
          permissions['dbg'] && (
            <IconButton
              onClick={() =>
                dispatch(appActions.setIsSecretActive(!isSecretActive))
              }
              className={clsx(isSecretActive && clsSecretActive)}
              size="large"
            >
              <BuildIcon />
            </IconButton>
          )
        }
      />
      <EditFormContent overflow="auto">
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Box p={1}>
            <Grid container>
              {isSecretActive && (
                <Grid item xs={12}>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    className={clsSecret}
                  >
                    /enterprises/{eid}/customers/{selected.id}
                  </Typography>
                </Grid>
              )}
              {!!selectedCurrentJourney && (
                <Grid item xs={12}>
                  <Card variant="outlined" sx={{ marginBottom: 1 }}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      p={2}
                      alignItems="center"
                    >
                      <InfoIcon fontSize="large" />
                      <Box marginLeft={2}>
                        <Typography variant="body1">
                          Este cliente já possui uma jornada em andamento
                        </Typography>
                      </Box>
                      <Box flexGrow={1} />
                      <Box marginLeft={2}>
                        <Button
                          onClick={handleShowJourneyClick}
                          variant="contained"
                          color="secondary"
                        >
                          VER JORNADA
                        </Button>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Dados Gerais
                </Typography>
              </Grid>

              <Grid container item spacing={2}>
                <Grid item md={6} sm={6} xs={12}>
                  <EditFormField
                    field={{
                      name: 'name',
                      label: 'Nome',
                      type: 'text',
                      tooltipHelpTitle: selectedCurrentJourney
                        ? 'Não é possível alterar o nome de um cliente se já existir uma jornada em andamento'
                        : '',
                    }}
                    control={control}
                    rules={{
                      required: 'Obrigatório',
                      minLength: { value: 4, message: 'Digite um nome válido' },
                    }}
                    disabled={!!selectedCurrentJourney}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <EditFormField
                      field={{
                        name: 'phone',
                        label: 'Telefone',
                        type: 'text',
                        tooltipHelpTitle: selectedCurrentJourney
                          ? 'Para alterar o telefone de um cliente com a jornada em andamento, clique em VER JORNADA e faça a alteração'
                          : '',
                      }}
                      control={control}
                      rules={{
                        required: 'Obrigatório',
                        validate: (value) => {
                          const { valid, error } = validateField(value, {
                            type: 'phone',
                          });
                          return valid || error;
                        },
                        minLength: {
                          value: 4,
                          message: 'Digite um telefone válido',
                        },
                      }}
                      disabled={!!selectedCurrentJourney}
                    />
                    {!selectedCurrentJourney && (
                      <SmartTip id="nono-digito">
                        <Typography>
                          DICA! Se o cliente não receber a mensagem, experimente
                          retirar o primeiro dígito.
                        </Typography>
                        <Box marginTop={2}>
                          <Typography>
                            Exemplo: Altere de (77) 9xxxx-xxxx para (77)
                            xxxx-xxxx
                          </Typography>
                        </Box>
                      </SmartTip>
                    )}
                  </Box>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <EditFormField
                    field={{
                      name: 'customerDocNumber',
                      label: 'CPF ou CNPJ (do responsável)',
                      type: 'text',
                      tooltipHelpTitle: selectedCurrentJourney
                        ? 'Não é possível alterar o documento de um cliente se já existir uma jornada em andamento'
                        : '',
                    }}
                    control={control}
                    rules={{
                      required: 'Obrigatório',
                      validate: (value) => {
                        const { valid, error } = validateField(value, {
                          type: 'docNumber',
                        });
                        return valid || error;
                        //testCPF(value) || testCNPJ(value) || 'CPF ou CNPJ inválido',
                      },
                      minLength: {
                        value: 4,
                        message: 'Digite um CPF ou CPNJ válido',
                      },
                    }}
                    disabled={!!selectedCurrentJourney}
                  />
                </Grid>
                {permissions['cus-cpc'] && (
                  <>
                    <Grid item md={6} sm={6} xs={12}>
                      <EditFormField
                        field={{
                          name: 'cpcStatus',
                          label: 'CPC',
                          type: 'select',
                          options: [
                            {
                              value: 'CPC_SUCCESS',
                              label: 'Confirmado',
                            },
                            {
                              value: 'CPC_INCOMPLETE',
                              label: 'CPC Não Concluído',
                            },
                            {
                              value: 'NO_CPC',
                              label: 'CPC Negativo',
                            },
                          ],
                        }}
                        control={control}
                        rules={{
                          required: 'Obrigatório',
                        }}
                      />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                      {selected.cpcAt && (
                        <Typography variant="body1">
                          Confirmado em{' '}
                          {moment(selected.cpcAt).format('DD/MM/YYYY')} às{' '}
                          {moment(selected.cpcAt).format('HH:mm:ss')}
                        </Typography>
                      )}
                    </Grid>
                    {(selected.noContactPhones ?? []).length > 0 && (
                      <Grid item md={6} sm={6} xs={12}>
                        <Typography variant="body2" color="textSecondary">
                          Os telefones abaixo não tiveram sucesso anteriormente:
                        </Typography>
                        {selected.noContactPhones.map(
                          (noContactPhone, index) => (
                            <Typography key={index}>
                              {formatValue(noContactPhone, 'phone')}
                            </Typography>
                          )
                        )}
                      </Grid>
                    )}
                    <Grid item md={6} sm={6} xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        Último contato feito a partir do número:
                      </Typography>
                      <Typography variant="body1">
                        {formatValue(selected.enterprisePhoneNumber, 'phone')}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Número em uso atualmente:
                      </Typography>
                      <Typography variant="body1">
                        {formatValue(phoneNumber, 'phone')}
                      </Typography>
                      {selected.enterprisePhoneNumber !== phoneNumber && (
                        <Typography variant="body1" color="secondary">
                          Cliente receberá aviso de mudança de chip
                        </Typography>
                      )}
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <TagField control={control} />
                </Grid>
              </Grid>
              {fields.map((invoice, index) => (
                <Box key={invoice.id} marginTop={2} padding={0}>
                  <Card variant="outlined">
                    <Box width={1} padding={2} marginTop={0}>
                      <Box marginBottom={1}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="flex-end"
                          paddingBottom={1}
                        >
                          <Typography variant="subtitle1">
                            Fatura #{index + 1}
                          </Typography>
                          <Box flexGrow={1} />
                          <Button
                            label="Excluir"
                            variant="outlined"
                            color="primary"
                            onClick={() => remove(index)}
                            disabled={index === 0}
                            startIcon={<DeleteIcon />}
                          >
                            Excluir
                          </Button>
                        </Box>
                        <Divider />
                      </Box>
                      <Grid container item spacing={2}>
                        {additionalFields && (
                          <>
                            {additionalFields.map((field) => (
                              <Grid
                                key={field.key}
                                item
                                md={
                                  field.type === 'message' ||
                                  field.type === 'file'
                                    ? 12
                                    : 6
                                }
                                sm={12}
                              >
                                <Box m={0} p={0} width={1}>
                                  <CustomField
                                    field={{
                                      ...field,
                                      name: `invoices.${index}.${field.name}`,
                                    }}
                                    defaultValue={
                                      field.name === 'docNumber'
                                        ? formatValue(
                                            invoice.docNumber,
                                            'docNumber'
                                          )
                                        : field.type === 'boolean'
                                        ? invoice[field.name] !== undefined
                                          ? invoice[field.name]
                                          : false
                                        : invoice[field.name]
                                    }
                                    control={control}
                                    isGlobalField={true}
                                    rules={
                                      field.name === 'invoiceValue'
                                        ? {
                                            validate: (value, row) => {
                                              if (
                                                pixAccount?.provider ===
                                                  'asaas' &&
                                                value -
                                                  row.invoices[index]
                                                    .invoiceDiscount <
                                                  5
                                              )
                                                return 'O valor da fatura com desconto não pode ser inferior a R$ 5,00';
                                            },
                                          }
                                        : field.name === 'invoiceDiscount'
                                        ? {
                                            validate: (value, row) => {
                                              if (
                                                pixAccount?.provider ===
                                                  'asaas' &&
                                                row.invoices[index]
                                                  .invoiceValue -
                                                  value <
                                                  5
                                              )
                                                return 'O valor da fatura com desconto não pode ser inferior a R$ 5,00';
                                            },
                                          }
                                        : field.rules
                                    }
                                  />
                                </Box>
                              </Grid>
                            ))}
                          </>
                        )}
                      </Grid>
                    </Box>
                  </Card>
                </Box>
              ))}
              <Box width={1} marginTop={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  label="Adicionar"
                  startIcon={<AddIcon />}
                  onClick={() => append({})}
                >
                  Adicionar
                </Button>
              </Box>
              <Box width={1} marginTop={2}>
                <Grid container item spacing={2}>
                  <Grid item md={6} sm={6} xs={12}>
                    <EditFormField
                      field={{
                        name: 'isActive',
                        label: 'Ativo',
                        type: 'boolean',
                      }}
                      defaultValue={selected.isActive ?? true}
                      control={control}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Box>
        </form>
      </EditFormContent>
      <Divider />
      <EditFormFooter>
        <EditFormMessage>
          {Object.keys(errors).length > 0 && (
            <Typography color="error" variant="caption">
              Os erros devem ser corrigidos antes de salvar
            </Typography>
          )}
        </EditFormMessage>
        <EditFormAction>
          {/* <ButtonWithProgress onClick={handleErrorClick}>
            Erro
          </ButtonWithProgress> */}
          <ButtonWithProgress
            // type="submit"
            tooltipTitle="Salvar"
            onClick={handleSaveClick}
            variant="contained"
            color="primary"
            disabled={!isDirty || isLoading}
            aria-label="salvar"
            startIcon={<SaveIcon />}
          >
            Salvar
          </ButtonWithProgress>
        </EditFormAction>
      </EditFormFooter>
      <CloseConfirmDialog />
      <ChangePhoneConfirmationDialog />
    </EditForm>
  );
}

EditCustomer.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default EditCustomer;
