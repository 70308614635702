import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Divider, Grid, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import Typography from '@mui/material/Typography';

import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';

import ButtonWithProgress from '../../components/ButtonWithProgress.jsx';
import ConfirmationDialog from '../../components/ConfirmationDialog.jsx';
import EditForm from '../../components/EditForm/EditForm.jsx';
import EditFormAction from '../../components/EditForm/EditFormAction.jsx';
import EditFormContent from '../../components/EditForm/EditFormContent.jsx';
import EditFormField from '../../components/EditForm/EditFormField.jsx';
import EditFormFooter from '../../components/EditForm/EditFormFooter.jsx';
import EditFormHeader from '../../components/EditForm/EditFormHeader.jsx';
import EditFormMessage from '../../components/EditForm/EditFormMessage.jsx';
import { usersActions } from './users-slice.js';

import ResponsiveDialog from '../../components/ResponsiveDialog.jsx';
import { levels } from '../auth/permissions';

function EditUser({ onClose }) {
  const {
    app: { isLoading },
    users: { selected, enterprises, selectedCustomClaims },
  } = useSelector((state) => state);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();

  const [isCloseConfirmationOpen, setIsCloseConfirmationOpen] = useState(false);
  const [isCustomClaimsOpen, setIsCustomClaimsOpen] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (selected?.id) dispatch(usersActions.getAuthUser(selected.id));
  }, [selected]);

  const onSubmit = (formData) => {
    const { id } = selected;
    const enterprisesToSave = formData.enterprises
      .filter((v) => !!v)
      .map((id) => ({
        id,
        name: enterprises[id].name,
      }));
    const dataToSave = {
      id,
      enterpriseId: enterprisesToSave[0].id,
      ...formData,
      enterprises: enterprisesToSave,
    };
    dispatch(usersActions.save(dataToSave));
  };
  const handleCloseClick = () => {
    if (!isDirty) onClose(false);
    else setIsCloseConfirmationOpen(true);
  };

  return (
    <EditForm>
      <EditFormHeader title="Detalhes" onCloseClick={handleCloseClick} />
      <EditFormContent overflow="auto">
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Box p={1}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Dados Gerais
                </Typography>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                  <EditFormField
                    field={{
                      name: 'displayName',
                      label: 'Nome',
                      type: 'text',
                    }}
                    defaultValue={selected.displayName ?? ''}
                    control={control}
                    rules={{
                      required: 'Obrigatório',
                      minLength: { value: 4, message: 'Digite um nome válido' },
                    }}
                  />
                </Grid>
                {/* <Grid item md={4} sm={4} xs={12}>
                  <EditFormField
                    field={{
                      name: 'enterpriseId',
                      label: 'Empresa Principal',
                      type: 'select',
                      options: Object.keys(enterprises).map((id) => ({
                        value: id,
                        label: enterprises[id].name,
                      })),
                    }}
                    defaultValue={selected.enterpriseId ?? ''}
                    control={control}
                    rules={{
                      required: 'Obrigatório',
                      minLength: { value: 4, message: 'Digite um nome válido' },
                    }}
                  />
                </Grid> */}
                <Grid item md={6} sm={6} xs={12}>
                  <EditFormField
                    field={{
                      name: 'role',
                      label: 'Perfil de Acesso',
                      type: 'select',
                      options: levels.map(({ role, label }) => ({
                        value: role,
                        label,
                      })),
                    }}
                    defaultValue={selected.role ?? 'adm'}
                    control={control}
                    rules={{
                      required: 'Obrigatório',
                      minLength: { value: 3, message: 'Digite um nome válido' },
                    }}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <EditFormField
                    field={{
                      name: 'enterprises',
                      label: 'Empresas sob gestão',
                      type: 'select',
                      options: [
                        ...Object.keys(enterprises).map((id) => ({
                          value: id,
                          label: enterprises[id].name,
                        })),
                      ].sort(({ label: a }, { label: b }) =>
                        a.localeCompare(b)
                      ),
                    }}
                    defaultValue={
                      selected.enterprises
                        ? selected.enterprises.map(({ id }) => id)
                        : []
                    }
                    control={control}
                    multiple
                    rules={{
                      required: 'Obrigatório',
                      minLength: { value: 4, message: 'Digite um nome válido' },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </form>
      </EditFormContent>
      <Divider />
      <EditFormFooter>
        <EditFormMessage>
          {Object.keys(errors).length > 0 && (
            <Typography color="error" variant="caption">
              Os erros devem ser corrigidos antes de salvar
            </Typography>
          )}
        </EditFormMessage>
        <EditFormAction>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box marginRight={1}>
              <IconButton
                onClick={(ev) => setIsCustomClaimsOpen(ev.currentTarget)}
                size="large"
              >
                {selectedCustomClaims &&
                selected.lastUpdate === selected.updatedClaimsCheck ? (
                  <LockIcon />
                ) : (
                  <LockOpenIcon color="error" />
                )}
              </IconButton>
            </Box>
            {(!selectedCustomClaims ||
              selected.lastUpdate !== selected.updatedClaimsCheck) && (
              <Box marginRight={1}>
                <ButtonWithProgress
                  tooltipTitle="Atualizar credenciais no autenticador"
                  onClick={() =>
                    dispatch(
                      usersActions.refreshUserClaimsClick({
                        uid: selected.id,
                      })
                    )
                  }
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  aria-label="atualizar"
                  startIcon={<RefreshIcon />}
                >
                  Atualizar Token
                </ButtonWithProgress>
              </Box>
            )}

            <ButtonWithProgress
              tooltipTitle="Salvar"
              // type="submit"
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
              disabled={!isDirty || isLoading}
              aria-label="salvar"
              startIcon={<SaveIcon />}
            >
              Salvar
            </ButtonWithProgress>
          </Box>
        </EditFormAction>
      </EditFormFooter>
      <ConfirmationDialog
        open={isCloseConfirmationOpen}
        title="Existem dados não salvos"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsCloseConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Sair sem Salvar',
            buttonProps: {
              onClick: () => onClose(false),
            },
          },
          {
            name: 'Salvar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'primary',
              startIcon: <SaveIcon />,
              onClick: handleSubmit(onSubmit),
            },
          },
        ]}
      >
        Alguns dados foram modificados e ainda não foram salvos.
        <br />
        <br />
        Deseja salvá-los antes de fechar a janela?
      </ConfirmationDialog>
      <ResponsiveDialog
        open={Boolean(isCustomClaimsOpen)}
        onClose={() => setIsCustomClaimsOpen(null)}
      >
        <Box p={2}>
          <Typography variant="subtitle1">Custom Claims</Typography>
          <Typography variant="caption">
            <pre>{JSON.stringify(selectedCustomClaims, null, 2)}</pre>
          </Typography>
        </Box>
      </ResponsiveDialog>
    </EditForm>
  );
}

EditUser.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default EditUser;
