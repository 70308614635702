import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import EditFormField from '../../components/EditForm/EditFormField';

const IntegrationsForm = ({ control, watchValues }) => {
  const {
    settings: { enterprise },
  } = useSelector((state) => state);

  // const [
  //   _enterpriseName,
  //   _enterpriseGender,
  //   _agentName,
  //   _agentGender,
  //   _waSessionId,
  //   _waNotificationPhone,
  //   _pixAccount,
  //   _pixKeyDestination,
  //   _pixRate,
  //   _pixRateType,
  //   integrationName,
  // ] = watchValues;
  // eslint-disable-next-line prefer-destructuring
  const integrationName = watchValues[10];

  return (
    <>
      <Typography variant="body2" color="textSecondary" component="p">
        Integrações
      </Typography>
      <Box marginTop={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <EditFormField
              field={{
                name: 'integrationName',
                label: 'Nome do sistema',
                type: 'select',
                options: [
                  {
                    value: '',
                    label: 'Nenhuma',
                  },
                  {
                    value: 'interfocus',
                    label: 'Interfocus',
                  },
                  {
                    value: 'ixc',
                    label: 'IXC',
                  },
                  {
                    value: 'sgp',
                    label: 'SGP',
                  },
                  {
                    value: 'apex',
                    label: 'Apex',
                  },
                ],
              }}
              defaultValue={enterprise.integration?.name ?? ''}
              control={control}
            />
          </Grid>
          {integrationName === 'interfocus' && (
            <>
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'integrationParameters.baseUrl',
                    label: 'URL Base',
                    type: 'text',
                  }}
                  defaultValue={
                    enterprise.integration?.parameters?.baseUrl ?? ''
                  }
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                    minLength: {
                      value: 4,
                      message: 'Digite uma url válida',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'integrationParameters.integrationSecret',
                    label: 'Integration Secret',
                    type: 'text',
                  }}
                  defaultValue={
                    enterprise.integration?.parameters?.integrationSecret ?? ''
                  }
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                    minLength: {
                      value: 4,
                      message: 'Digite uma url válida',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'integrationParameters.chaveBase',
                    label: 'Chave Base',
                    type: 'text',
                  }}
                  defaultValue={
                    enterprise.integration?.parameters?.chaveBase ?? ''
                  }
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                    minLength: {
                      value: 4,
                      message: 'Digite uma chave válida',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'integrationParameters.usuario',
                    label: 'Usuário',
                    type: 'text',
                  }}
                  defaultValue={
                    enterprise.integration?.parameters?.usuario ?? ''
                  }
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                    minLength: {
                      value: 4,
                      message: 'Digite um usuário válido',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'integrationParameters.bancopagamento',
                    label: 'Banco Pagamento',
                    type: 'text',
                  }}
                  defaultValue={
                    enterprise.integration?.parameters?.bancopagamento ?? ''
                  }
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                    minLength: {
                      value: 2,
                      message: 'Digite um código válido',
                    },
                  }}
                />
              </Grid>
            </>
          )}

          {integrationName === 'sgp' && (
            <>
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'integrationParameters.baseUrl',
                    label: 'URL Base',
                    type: 'text',
                  }}
                  defaultValue={
                    enterprise.integration?.parameters?.baseUrl ?? ''
                  }
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                    minLength: {
                      value: 4,
                      message: 'Digite uma url válida',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'integrationParameters.token',
                    label: 'Token',
                    type: 'text',
                  }}
                  defaultValue={enterprise.integration?.parameters?.token ?? ''}
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                    minLength: {
                      value: 4,
                      message: 'Digite um token válido',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'integrationParameters.app',
                    label: 'Nome do App',
                    type: 'text',
                  }}
                  defaultValue={enterprise.integration?.parameters?.app ?? ''}
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                    minLength: {
                      value: 4,
                      message: 'Digite uma chave válida',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'integrationParameters.portador',
                    label: 'ID do portador',
                    type: 'text',
                  }}
                  defaultValue={
                    enterprise.integration?.parameters?.portador ?? ''
                  }
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                    minLength: {
                      value: 1,
                      message: 'Digite um portador válido',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'integrationParameters.nomePortador',
                    label: 'Nome do portador',
                    type: 'text',
                  }}
                  defaultValue={
                    enterprise.integration?.parameters?.nomePortador ?? ''
                  }
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                    minLength: {
                      value: 1,
                      message: 'Digite um nome de portador válido',
                    },
                  }}
                />
              </Grid>
            </>
          )}

          {integrationName === 'ixc' && (
            <>
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'integrationParameters.baseUrl',
                    label: 'URL Base (ex: https://demo.ixcsoft.com.br/)',
                    type: 'text',
                  }}
                  defaultValue={
                    enterprise.integration?.parameters?.baseUrl ?? ''
                  }
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                    minLength: {
                      value: 4,
                      message: 'Digite uma url válida',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'integrationParameters.token',
                    label: 'Token',
                    type: 'text',
                  }}
                  defaultValue={enterprise.integration?.parameters?.token ?? ''}
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                    minLength: {
                      value: 4,
                      message: 'Digite um token válido',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'integrationParameters.idFilial',
                    label: 'ID da Filial',
                    type: 'text',
                  }}
                  defaultValue={
                    enterprise.integration?.parameters?.idFilial ?? ''
                  }
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                    minLength: {
                      value: 1,
                      message: 'Digite um valor válido',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'integrationParameters.idCarteira',
                    label: 'ID Carteira',
                    type: 'text',
                  }}
                  defaultValue={
                    enterprise.integration?.parameters?.idCarteira ?? ''
                  }
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                    minLength: {
                      value: 1,
                      message: 'Digite uma conta válida',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'integrationParameters.contaAnalitico',
                    label: 'Conta (Planejamento Analítico)',
                    type: 'text',
                  }}
                  defaultValue={
                    enterprise.integration?.parameters?.contaAnalitico ?? ''
                  }
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                    minLength: {
                      value: 1,
                      message: 'Digite uma conta válida',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'integrationParameters.idConta',
                    label: 'Conta (id_conta)',
                    type: 'text',
                  }}
                  defaultValue={
                    enterprise.integration?.parameters?.idConta ?? ''
                  }
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                    minLength: {
                      value: 1,
                      message: 'Digite uma conta válida',
                    },
                  }}
                />
              </Grid>
            </>
          )}

          {integrationName === 'apex' && (
            <>
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'integrationParameters.baseUrl',
                    label: 'URL Base',
                    type: 'text',
                  }}
                  defaultValue={
                    enterprise.integration?.parameters?.baseUrl ?? ''
                  }
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                    minLength: {
                      value: 4,
                      message: 'Digite uma url válida',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'integrationParameters.token',
                    label: 'Token',
                    type: 'text',
                  }}
                  defaultValue={enterprise.integration?.parameters?.token ?? ''}
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                    minLength: {
                      value: 4,
                      message: 'Digite um token válido',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <EditFormField
                  field={{
                    name: 'integrationParameters.banco',
                    label: 'Nome do banco',
                    type: 'text',
                  }}
                  defaultValue={enterprise.integration?.parameters?.app ?? ''}
                  control={control}
                  rules={{
                    required: 'Obrigatório',
                    minLength: {
                      value: 4,
                      message: 'Digite um banco válido',
                    },
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};
IntegrationsForm.propTypes = {
  control: PropTypes.object,
  watchValues: PropTypes.array,
};
export default IntegrationsForm;
