'use strict';
import BusinessIcon from '@mui/icons-material/Business';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import FaceIcon from '@mui/icons-material/Face';
import LabelIcon from '@mui/icons-material/Label';
import LabelOffIcon from '@mui/icons-material/LabelOff';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmptyContent from '../../components/EmptyContent';
import EnhancedTable from '../../components/EnhancedTable/EnhancedTable';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import TagChip from '../../components/Tags/TagChip';
import { formatCustomFieldValue, formatValue } from '../../helpers/formatter';
import removeSpecialChars from '../../helpers/remove-special-chars';
import JourneyDetail from '../monitor/JourneyDetail';
import { monitorActions } from '../monitor/monitor-slice';
import ApplyTagsForm from './ApplyTagsForm';
import CustomerSearchForm from './CustomerSearchForm';
import EditCustomer from './EditCustomer';
import { customersActions } from './customers-slice';

const CustomerNameWithIcon = ({ name, type }) => {
  return (
    <Box display="flex" flexDirection="row">
      {type === 'person' && <FaceIcon />}
      {type === 'business' && <BusinessIcon />}
      <Box marginLeft={1}>
        <Typography>{name}</Typography>
      </Box>
    </Box>
  );
};
CustomerNameWithIcon.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const Check = ({ checked }) => {
  if (checked) return <CheckIcon style={{ color: green[500] }} />;
  else return <CloseIcon color="error" />;
};
Check.propTypes = {
  checked: PropTypes.bool,
};

const TagComponent = (props) => {
  return <TagChip {...props} size="small" />;
};

const startColumns = [
  {
    id: 'nameWithFilter',
    numeric: false,
    disablePadding: true,
    label: 'Nome',
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
    label: 'Telefone',
    formatter: (v) => formatValue(v, 'phone'),
  },
  {
    id: 'customerDocNumber',
    numeric: false,
    disablePadding: false,
    label: 'Documento',
    formatter: (v) => formatValue(v, 'docNumber'),
  },
];
const endColumns = [
  {
    id: 'tags',
    numeric: false,
    disablePadding: true,
    align: 'center',
    label: 'Marcadores',
    formatter: (value) => TagComponent({ value }),
  },
  {
    id: 'cpcStatusWithSort',
    numeric: false,
    disablePadding: false,
    align: 'center',
    label: 'CPC',
    formatter: (v) => Check({ checked: v.value }),
  },
  {
    id: 'isActiveWithSort',
    numeric: false,
    disablePadding: false,
    align: 'center',
    label: 'Ativo',
    formatter: (v) => Check({ checked: v.value }),
  },
];

const filters = {
  keywords: {
    type: 'keywords',
    label: 'Palavras-chave',
    currentValue: '',
  },
  cpcStatusWithSort: {
    type: 'boolean',
    label: 'CPC',
    currentValue: { true: true, false: true },
  },
  isActiveWithSort: {
    type: 'boolean',
    label: 'Ativo',
    currentValue: { true: true, false: true },
  },
  tags: {
    type: 'tag-list',
    label: 'Marcadores',
    currentValue: [],
  },
};

const expandObjectToColumns = (obj) => {
  const keys = _.uniq(obj.map((i) => Object.keys(i)).flat());
  return obj.reduce((acc, cur) => {
    keys.forEach((key) => {
      if (!acc[key]) acc[key] = [];
      acc[key].push(cur[key]);
    });
    return acc;
  }, {});
};

function Customers() {
  const {
    customers: { data, selected, metadata, recordsLimit, searchState },
    settings: { enterprise: { customersTypes } = {} },
    auth: { permissions },
  } = useSelector((state) => state);

  const { journeys, isLoadingJourneys } = useSelector((state) => state.monitor);

  const [idsToApplyTags, setIdsToApplyTags] = useState();

  const enhancedData = data.map((row) => ({
    ...row,
    tags: row?.tags ?? [],
    nameWithFilter: {
      value: row.name,
      props: {
        sortValue: row.name,
        filterValue: removeSpecialChars(row.name),
      },
    },
    ...(row.invoices
      ? expandObjectToColumns(row.invoices.map((i) => _.omit(i, ['tags'])))
      : {}),
    isActiveWithSort: {
      value: !!row.isActive,
      props: {
        sortValue: row.isActive ? 0 : 1,
      },
    },
    cpcStatusWithSort: {
      value: row.cpcStatus === 'CPC_SUCCESS',
      props: {
        sortValue: row.cpcStatus === 'CPC_SUCCESS' ?? 1 ? 0 : 1,
      },
    },

    keywords: removeSpecialChars(
      [
        row.name,
        row.phone,
        ..._.flatten(
          row.invoices
            ? row.invoices.map((invoice) => [
                invoice.invoiceId,
                invoice.docNumber,
              ])
            : []
        ),
        ...(row.tags ?? []),
      ].join(' ')
    ),
  }));

  const additionalColumns =
    Object.keys(metadata).length > 0 &&
    customersTypes &&
    customersTypes.length > 0
      ? _.uniq([
          // {
          //   id: 'docNumber',
          //   numeric: false,
          //   disablePadding: false,
          //   align: 'center',
          //   label: 'Documento',
          //   formatter: (v) => formatValue(v, 'docNumber'),
          // },
          ...customersTypes
            .map((id) => {
              const props = metadata[id].properties;
              const keys = Object.keys(props);
              return keys.map((key) => ({ key, name: key, ...props[key] }));
            })
            .flat()
            .filter(({ showInListView }) => !!showInListView)
            .sort((a, b) => a.showOrder - b.showOrder)
            .map((field) => ({
              id: field.key,
              numeric: false,
              disablePadding: false,
              align: 'center',
              label: field.label,
              formatter: (v) => formatCustomFieldValue(v, field),
            })),
        ])
      : [];

  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(customersActions.load());
    dispatch(customersActions.loadMetadata());

    return () => {
      dispatch(customersActions.exit());
      dispatch(
        customersActions.selectCustomer({
          selected: undefined,
          selectedContext: undefined,
        })
      );
    };
  }, [dispatch]);

  const handleClose = (reload) => {
    if (reload) dispatch(customersActions.load(searchState.inputs));
    dispatch(
      customersActions.selectCustomer({
        selected: undefined,
        selectedContext: undefined,
      })
    );
  };
  const handleCustomerClick = (customer, context) => {
    dispatch(
      customersActions.selectCustomer({
        selected: customer,
        selectedContext: context,
      })
    );
  };
  const handleDeleteConfirm = (selected) => {
    dispatch(customersActions.delete(selected));
  };

  const handleSearch = (searchData) => {
    dispatch(customersActions.load(searchData));
  };

  const subtitle =
    enhancedData.length === recordsLimit + 1
      ? `Atenção! Exibindo somente os primeiros ${recordsLimit} registros. Caso necessário faça uma busca mais detalhada`
      : undefined;

  return (
    <div>
      <EnhancedTable
        title="Clientes"
        subtitle={subtitle}
        onRowClick={(d) => handleCustomerClick(d, 'edit')}
        data={enhancedData.slice(0, recordsLimit)}
        columns={[...startColumns, ...additionalColumns, ...endColumns]}
        filters={filters}
        orderBy="nameWithFilter"
        onDeleteConfirm={
          permissions['cus-del'] ? handleDeleteConfirm : undefined
        }
        onNewClick={
          permissions['cus-add']
            ? () => handleCustomerClick({}, 'new')
            : undefined
        }
        tableProps={{ size: 'small' }}
        rowsPerPage={100}
        searchField={<CustomerSearchForm onSearch={handleSearch} />}
        extraMenu={{
          items: [
            {
              handler: (ids) => {
                dispatch(
                  customersActions.batchUpdate({
                    ids,
                    data: { isActive: true },
                  })
                );
              },
              Icon: PlayArrowIcon,
              confirm: true,
              text: 'Marcar como ATIVO',
            },
            {
              handler: (ids) => {
                dispatch(
                  customersActions.batchUpdate({
                    ids,
                    data: { isActive: false },
                  })
                );
              },
              Icon: PauseIcon,
              confirm: true,
              text: 'Marcar como INATIVO',
            },
            {
              handler: (ids) => {
                setIdsToApplyTags(ids);
              },
              Icon: LabelIcon,
              confirm: false,
              text: 'Aplicar marcadores',
            },
            {
              handler: (ids) => {
                dispatch(
                  customersActions.batchUpdate({
                    ids,
                    data: { tags: [] },
                  })
                );
              },
              Icon: LabelOffIcon,
              confirm: true,
              text: 'Limpar marcadores',
            },
          ],
        }}
      />
      {enhancedData.length === 0 && (
        <Box display="flex" flexDirection="column">
          {searchState.firstSearch && (
            <EmptyContent
              title="Nada!"
              detail="Tente utilizar a palavra completa, ou o documento completo."
            />
          )}
        </Box>
      )}
      <ResponsiveDialog
        open={selected !== undefined}
        fullHeight
        maxWidth="md"
        fullWidth
        disableEscapeKeyDown
      >
        {selected && <EditCustomer data={selected} onClose={handleClose} />}
      </ResponsiveDialog>
      <ResponsiveDialog
        open={!!idsToApplyTags}
        maxWidth="xs"
        onClose={() => setIdsToApplyTags()}
      >
        <ApplyTagsForm
          onClose={() => setIdsToApplyTags()}
          onApply={(tags) => {
            // console.log(tags);
            dispatch(
              customersActions.batchUpdate({
                ids: idsToApplyTags,
                data: { tags },
                field: 'tags',
              })
            );
            setIdsToApplyTags();
          }}
        />
      </ResponsiveDialog>
      <Backdrop open={isLoadingJourneys}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {journeys && (
        <ResponsiveDialog
          open={!!journeys}
          maxWidth="xl"
          fullWidth
          fullHeight
          onClose={() => {
            dispatch(monitorActions.closeDetail(false));
          }}
        >
          <JourneyDetail
            onClose={(dataUpdated) => {
              dispatch(monitorActions.closeDetail(dataUpdated));
            }}
          />
        </ResponsiveDialog>
      )}
    </div>
  );
}

export default Customers;
