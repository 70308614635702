import SaveIcon from '@mui/icons-material/Save';
import { Badge, Box, Divider, Tab, Tabs } from '@mui/material';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import 'moment/locale/pt-br';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ButtonWithProgress from '../../components/ButtonWithProgress';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import EditForm from '../../components/EditForm/EditForm';
import EditFormAction from '../../components/EditForm/EditFormAction';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormFooter from '../../components/EditForm/EditFormFooter';
import EditFormHeader from '../../components/EditForm/EditFormHeader';
import EditFormMessage from '../../components/EditForm/EditFormMessage';
import firebase from '../../helpers/firebase';
import DigitalAgentForm from './DigitalAgentForm';
import EnterpriseForm from './EnterpriseForm';
import GeneralSettingsForm from './GeneralSettingsForm';
import IntegrationsForm from './IntegrationsForm';
import PixForm from './PixForm';
import TagsForm from './TagsForm';
import WAForm from './WAForm';
import { settingsActions } from './settings-slice';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      minHeight="70vh"
      width={1}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      p={2}
      {...other}
    >
      {children}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `tab-${index}`,
//     'aria-controls': `tabpanel-${index}`,
//   };
// }

const useStyles = makeStyles((theme) => ({
  tabs: {
    width: 200,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function Settings({ onClose }) {
  const classes = useStyles();
  const {
    app: { isLoading },
    settings: { enterprise, adminRequired, currentTab },
    auth: { permissions },
  } = useSelector((state) => state);
  const [isCloseConfirmationOpen, setIsCloseConfirmationOpen] =
    React.useState(false);
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    trigger,
    reset,
  } = useForm({
    defaultValues: {
      tags: enterprise.tags,
      enterpriseName: enterprise.name ?? '',
      enterpriseGender: enterprise.gender ?? '',
      agentName: enterprise?.agent?.name ?? '',
      agentGender: enterprise?.agent?.gender ?? '',
      waSessionId: enterprise.waSessionId ?? '',
      waNotificationPhone: enterprise.waNotificationPhone ?? '',
      pixAccount: enterprise.pixAccount ?? '',
      pixKeyDestination: enterprise.pixKeyDestination ?? '',
      pixRate: enterprise.pixRate ?? '',
      pixRateType: enterprise.pixRateType ?? '',
      integrationName: enterprise.integration?.name ?? '',
      integrationParameters: enterprise.integration?.parameters ?? {},
      inTrial: enterprise.inTrial ?? false,
      trialEnd: enterprise.trialEnd
        ? moment(enterprise.trialEnd).format('YYYY-MM-DD')
        : '',
    },
  });

  const watchValues = useWatch({
    control,
    name: [
      'enterpriseName',
      'enterpriseGender',
      'agentName',
      'agentGender',
      'waSessionId',
      'waNotificationPhone',
      'pixAccount',
      'pixKeyDestination',
      'pixRate',
      'pixRateType',
      'integrationName',
      'integrationParameters',
      'inTrial',
      'trialEnd',
    ],
  });
  const generalErrors = errors.defaultJourneyId;
  const enterpriseErrors = errors.enterpriseGender || errors.enterpriseName;
  const agentErrors = errors.agentName || errors.agentGender;
  const waErrors = errors.waSessionId || errors.waNotificationPhone;
  const pixErrors =
    errors.pixAccount ||
    errors.pixKeyDestination ||
    errors.pixRate ||
    errors.pixRateType;
  const integrationsErrors = errors.integrationName || errors.integrationProps;
  const tagsErrors = errors.tags;

  const onSubmit = ({
    enterpriseName,
    enterpriseGender,
    agentName,
    agentGender,
    waSessionId,
    waNotificationPhone,
    pixAccount,
    pixKeyDestination,
    pixKeyDestinationType,
    pixRate,
    pixRateType,
    appEnabled,
    appWebhook,
    appApiKey,
    defaultJourneyId,
    tags,
    integrationName,
    integrationParameters,
    ignoreCustomer,
    inTrial,
    trialEnd,
    parentEnterprise,
  }) => {
    // console.log(
    //   _.omitBy(
    //     {
    //       enterpriseName,
    //       enterpriseGender,
    //       agentName,
    //       agentGender,
    //       waSessionId,
    //       pixAccount,
    //       pixKeyDestination,
    //     },
    //     _.isUndefined
    //   )
    // );

    dispatch(
      settingsActions.saveEnterprise({
        id: enterprise.id,
        name: enterpriseName,
        gender: enterpriseGender,
        agent: {
          name: agentName,
          gender: agentGender,
        },
        application: {
          enabled: appEnabled,
          webhook: appWebhook,
          apiKey: appApiKey,
        },
        waSessionId,
        waNotificationPhone,
        pixAccount,
        pixKeyDestination,
        pixKeyDestinationType,
        pixRate,
        pixRateType,
        tags,
        ignoreCustomer,
        journeyId: defaultJourneyId,
        integration:
          integrationName !== ''
            ? {
                name: integrationName,
                parameters: integrationParameters,
              }
            : firebase.firestore.FieldValue.delete(),
        inTrial,
        trialEnd: moment(trialEnd).valueOf(),
        parentEnterprise,
      })
    );
    setIsCloseConfirmationOpen(false);
    reset({}, { keepValues: true });
  };

  const handleCloseClick = () => {
    if (!isDirty) onClose(false);
    else setIsCloseConfirmationOpen(true);
  };

  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <form noValidate autoComplete="off">
      <EditForm>
        <EditFormHeader title="Ajustes" onCloseClick={handleCloseClick} />
        <EditFormContent>
          <Divider />
          <Box display="flex" flexDirection="row">
            <Tabs
              orientation="vertical"
              value={currentTab}
              onChange={(_, newValue) => {
                dispatch(settingsActions.setCurrentTab(newValue));
              }}
              className={classes.tabs}
            >
              <Tab
                value="cfg-gen"
                label={
                  <Badge
                    invisible={!generalErrors}
                    overlap="rectangular"
                    badgeContent="!"
                    color="error"
                  >
                    <Typography variant="body2">Geral</Typography>
                  </Badge>
                }
                // {...a11yProps(0)}
              />
              {permissions['cfg-ent'] && (
                <Tab
                  value="cfg-ent"
                  label={
                    <Badge
                      invisible={!enterpriseErrors}
                      overlap="rectangular"
                      badgeContent="!"
                      color="error"
                    >
                      <Typography variant="body2">Empresa</Typography>
                    </Badge>
                  }
                  // {...a11yProps(1)}
                />
              )}
              {permissions['cfg-ent'] && (
                <Tab
                  value="cfg-ent2"
                  label={
                    <Badge
                      invisible={!agentErrors}
                      overlap="rectangular"
                      badgeContent="!"
                      color="error"
                    >
                      <Typography variant="body2">Agente Digital</Typography>
                    </Badge>
                  }
                  // {...a11yProps(2)}
                />
              )}
              {permissions['cfg-msg'] && (
                <Tab
                  value="cfg-msg"
                  label={
                    <Badge
                      invisible={!waErrors}
                      overlap="rectangular"
                      badgeContent="!"
                      color="error"
                    >
                      <Typography variant="body2">WhatsApp</Typography>
                    </Badge>
                  }
                  // {...a11yProps(3)}
                />
              )}
              {permissions['cfg-tag'] && (
                <Tab
                  value="cfg-tag"
                  label={
                    <Badge
                      invisible={!tagsErrors}
                      overlap="rectangular"
                      badgeContent="!"
                      color="error"
                    >
                      <Typography variant="body2">Marcadores</Typography>
                    </Badge>
                  }
                  // {...a11yProps(4)}
                />
              )}
              {permissions['cfg-pix'] && (
                <Tab
                  value="cfg-pix"
                  label={
                    <Badge
                      invisible={!pixErrors}
                      overlap="rectangular"
                      badgeContent="!"
                      color="error"
                    >
                      <Typography variant="body2">Pix</Typography>
                    </Badge>
                  }
                  // {...a11yProps(5)}
                />
              )}
              {permissions['cfg-int'] && (
                <Tab
                  value="cfg-int"
                  label={
                    <Badge
                      invisible={!integrationsErrors}
                      overlap="rectangular"
                      badgeContent="!"
                      color="error"
                    >
                      <Typography variant="body2">Integrações</Typography>
                    </Badge>
                  }
                  // {...a11yProps(5)}
                />
              )}
            </Tabs>
            <TabPanel value={currentTab} index="cfg-gen">
              <GeneralSettingsForm
                control={control}
                watchValues={watchValues}
              />
            </TabPanel>
            <TabPanel value={currentTab} index="cfg-ent">
              <EnterpriseForm control={control} watchValues={watchValues} />
            </TabPanel>
            <TabPanel value={currentTab} index="cfg-ent2">
              <DigitalAgentForm control={control} watchValues={watchValues} />
            </TabPanel>
            <TabPanel value={currentTab} index="cfg-msg">
              <WAForm control={control} watchValues={watchValues} />
            </TabPanel>
            <TabPanel value={currentTab} index="cfg-tag">
              <TagsForm control={control} watchValues={watchValues} />
            </TabPanel>
            <TabPanel value={currentTab} index="cfg-pix">
              <PixForm control={control} watchValues={watchValues} />
            </TabPanel>
            <TabPanel value={currentTab} index="cfg-int">
              <IntegrationsForm control={control} watchValues={watchValues} />
            </TabPanel>
          </Box>
        </EditFormContent>
        <Divider />
        <EditFormFooter>
          <EditFormMessage>
            {Object.keys(errors).length > 0 && (
              <Typography color="error" variant="caption">
                Os erros devem ser corrigidos antes de salvar
              </Typography>
            )}
            {Object.keys(errors).length === 0 && adminRequired && (
              <Typography color="error" variant="caption">
                Contate a equipe da ZetaOne para concluir seu cadastro
              </Typography>
            )}
          </EditFormMessage>
          <EditFormAction>
            <ButtonWithProgress
              tooltipTitle="Salvar"
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
              disabled={!isDirty || isLoading}
              aria-label="salvar"
              startIcon={<SaveIcon />}
            >
              Salvar
            </ButtonWithProgress>
          </EditFormAction>
        </EditFormFooter>
        <ConfirmationDialog
          open={isCloseConfirmationOpen}
          title="Existem dados não salvos"
          actions={[
            {
              name: 'Cancelar',
              buttonProps: {
                autoFocus: true,
                onClick: () => {
                  setIsCloseConfirmationOpen(false);
                },
              },
            },
            {
              name: 'Sair sem Salvar',
              buttonProps: {
                onClick: () => onClose(false),
              },
            },
            {
              name: 'Salvar',
              showLoading: true,
              buttonProps: {
                variant: 'contained',
                color: 'primary',
                startIcon: <SaveIcon />,
                onClick: handleSubmit(onSubmit),
              },
            },
          ]}
        >
          Alguns dados foram modificados e ainda não foram salvos.
          <br />
          <br />
          Deseja salvá-los antes de fechar a janela?
        </ConfirmationDialog>
      </EditForm>
    </form>
  );
}

Settings.propTypes = {
  onClose: PropTypes.func,
};
