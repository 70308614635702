import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const defaultTab = 'cfg-gen';
export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    theme: 'light',
    enterprise: {},
    waSessions: [],
    pixAccounts: [],
    isOpen: false,
    configRequired: false,
    adminRequired: false,
    limitedAccess: true,
    trialDaysLeft: 0,
    localApi: false,
    currentTab: defaultTab,
    pixAccount: {},
    isSubaccountFormOpen: false,
    subaccountDocumentStatus: undefined,
    isSubaccountEditFormOpen: false,
  },
  reducers: {
    openSettings: (state, action) => {
      state.isOpen = true;
      state.currentTab = action?.payload ?? defaultTab;
    },
    closeSettings: (state) => {
      state.isOpen = false;
    },
    setCurrentTab: (state, action) => {
      state.currentTab = action?.payload ?? defaultTab;
    },
    init: () => {},
    initSuccess: (state, action) => {
      return { ...state, ...action.payload };
    },
    changeSettings: (state, action) => {
      return { ...state, ...action.payload };
    },
    loadEnterprise: () => {},
    loadEnterpriseSuccess: (state, action) => {
      state.enterprise = action.payload;
      state.configRequired =
        !state.enterprise.name ||
        !state.enterprise.gender ||
        !state.enterprise?.agent?.name ||
        !state.enterprise?.agent?.gender ||
        !state.enterprise.waNotificationPhone;

      state.adminRequired =
        !state.enterprise.waSessionId ||
        !state.enterprise.pixAccount ||
        !state.enterprise.pixKeyDestination ||
        state.enterprise.pixRate === undefined ||
        !state.enterprise.pixRateType ||
        !state.enterprise?.journeyId;

      state.trialDaysLeft = Math.floor(
        (
          state.enterprise.trialEnd - moment(Date.now()).startOf('day')
        ).valueOf() /
          1000 /
          3600 /
          24
      );
      state.limitedAccess =
        state.configRequired ||
        state.adminRequired ||
        (state.enterprise.inTrial && state.trialDaysLeft < 0);
    },
    loadEnterpriseFail: (state) => {
      state.enterprise = {};
    },
    saveEnterprise: () => {},
    saveEnterpriseSuccess: (state, action) => {
      state.enterprise = { ...state.enterprise, ...action.payload };
    },
    saveEnterpriseFail: (state, action) => {
      state.isError = true;
      state.error = action.payload;
    },
    reset: (state) => {
      state.enterprise = {};
      state.isOpen = false;
    },
    getWASessions: () => {},
    getWASessionsSuccess: (state, action) => {
      state.waSessions = action.payload;
    },
    getWASessionsFail: (state) => {
      state.waSessions = [];
    },
    getPixAccounts: () => {},
    getPixAccountsSuccess: (state, action) => {
      state.pixAccounts = action.payload;
    },
    getPixAccountsFail: (state) => {
      state.pixAccounts = [];
    },
    getPixAccount: () => {},
    setPixAccount: (state, action) => {
      state.pixAccount = action.payload;
    },
    setPixSubaccount: (state, action) => {
      state.pixSubaccount = action.payload;
    },
    getPixSubaccount: () => {},
    createSubaccount: () => {},
    createSubaccountSuccess: () => {},
    createSubaccountFail: () => {},
    updateSubaccount: () => {},
    updateSubaccountSuccess: () => {},
    updateSubaccountFail: () => {},
    setIsSubaccountFormOpen: (state, action) => {
      state.isSubaccountFormOpen = action.payload;
    },
    getSubaccountDocumentStatus: () => {},
    getSubaccountDocumentStatusSuccess: (state, action) => {
      state.subaccountDocumentStatus = action.payload;
    },
    getSubaccountDocumentStatusFail: (state) => {
      state.subaccountDocumentStatus = undefined;
    },
    activateSubaccountPixKey: () => {},
    activateSubaccountPixKeySuccess: () => {},
    activateSubaccountPixKeyFail: () => {},
    setIsSubaccountEditFormOpen: (state, action) => {
      state.isSubaccountEditFormOpen = action.payload;
    },
  },
});

export const settingsActions = settingsSlice.actions;

export default settingsSlice.reducer;
