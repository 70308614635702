import {
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditFormField from '../../components/EditForm/EditFormField';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import SubaccountForm from './SubaccountForm';
import SubaccountStatus from './SubaccountStatus';
import { settingsActions } from './settings-slice';

const PixForm = ({ control }) => {
  const dispatch = useDispatch();

  const {
    enterprise,
    pixAccounts,
    pixAccount,
    pixSubaccount,
    isSubaccountFormOpen,
  } = useSelector((state) => state.settings);
  useEffect(() => {
    dispatch(settingsActions.getPixAccounts());
    dispatch(settingsActions.getPixAccount());
  }, [dispatch]);

  const { accountStatus } = pixSubaccount ?? {};

  return (
    <>
      <Typography variant="body2" color="textSecondary" component="p">
        Informações do Pix
      </Typography>
      <Box marginTop={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {pixAccounts.length > 0 && (
              <EditFormField
                field={{
                  name: 'pixAccount',
                  label: 'Conta',
                  type: 'select',
                  options: pixAccounts.map(({ id: value, label }) => ({
                    value,
                    label,
                  })),
                }}
                defaultValue={enterprise.pixAccount ?? ''}
                control={control}
                rules={{
                  required: 'Obrigatório',
                }}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <EditFormField
              field={{
                name: 'pixKeyDestinationType',
                label: 'Tipo de Chave PIX para Repasse',
                type: 'select',
                options: [
                  {
                    value: 'CPF',
                    label: 'CPF',
                  },
                  {
                    value: 'CNPJ',
                    label: 'CNPJ',
                  },
                  {
                    value: 'EMAIL',
                    label: 'e-mail',
                  },
                  {
                    value: 'PHONE',
                    label: 'Telefone',
                  },
                  {
                    value: 'EVP',
                    label: 'Chave aleatória',
                  },
                ],
              }}
              defaultValue={enterprise.pixKeyDestinationType ?? ''}
              control={control}
              rules={{
                required: 'Obrigatório',
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <EditFormField
              field={{
                name: 'pixKeyDestination',
                label: 'Chave PIX para Repasse',
                type: 'text',
              }}
              defaultValue={enterprise.pixKeyDestination ?? ''}
              control={control}
              rules={{
                required: 'Obrigatório',
                minLength: {
                  value: 4,
                  message: 'Digite uma chave válida',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <EditFormField
              field={{
                name: 'pixRateType',
                label: 'Tipo de tarifação',
                type: 'select',
                options: [
                  {
                    value: 'online',
                    label: 'Desconto direto no repasse',
                  },
                  {
                    value: 'after',
                    label: 'Mensal',
                  },
                ],
              }}
              defaultValue={enterprise.pixRateType ?? ''}
              control={control}
              rules={{
                required: 'Obrigatório',
                minLength: {
                  value: 4,
                  message: 'Digite um valor válido',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <EditFormField
              field={{
                name: 'pixRate',
                label: 'Tarifa por pix recebido',
                type: 'decimal',
                inputAdornment: {
                  position: 'start',
                  text: 'R$',
                },
              }}
              defaultValue={enterprise.pixRate ?? ''}
              control={control}
              rules={{
                required: 'Obrigatório',
                minLength: {
                  value: 4,
                  message: 'Digite um valor válido',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          {pixSubaccount === undefined &&
            !pixAccount.parentAccountId &&
            pixAccount.provider === 'asaas' && (
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    dispatch(settingsActions.setIsSubaccountFormOpen(true));
                  }}
                >
                  Ativar subconta
                </Button>
              </Grid>
            )}
          {pixSubaccount && (
            <>
              <Box marginTop={1} width={1}>
                <Divider />
              </Box>
              <Box p={1} marginTop={1} width={1}>
                <Grid container spacing={2}>
                  {!accountStatus && (
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        Informações da subconta
                      </Typography>
                      <Box marginTop={2}>
                        <Typography variant="body1" align="center">
                          Requisição enviada, aguardando atualização...
                        </Typography>
                      </Box>
                      <Box marginTop={1}>
                        <LinearProgress />
                      </Box>
                    </Grid>
                  )}
                  {accountStatus && <SubaccountStatus />}
                </Grid>
              </Box>
            </>
          )}
        </Grid>
      </Box>
      <ResponsiveDialog open={isSubaccountFormOpen} disableEscapeKeyDown>
        <SubaccountForm
          onCloseClick={() =>
            dispatch(settingsActions.setIsSubaccountFormOpen(false))
          }
        />
      </ResponsiveDialog>
    </>
  );
};
PixForm.propTypes = {
  control: PropTypes.object,
  watchValues: PropTypes.array,
};
export default PixForm;
