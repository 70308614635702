'use strict';
import { Box, Divider, Tab, Tabs, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      minHeight="70vh"
      width={1}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      p={2}
      {...other}
    >
      {children}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    width: 200,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function Help() {
  const classes = useStyles();

  const [currentTab, setCurrentTab] = useState('intro');
  const [currentMd, setCurrentMd] = useState('intro');
  useEffect(() => {
    import(`./${currentTab}.md`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then(setCurrentMd)
          // eslint-disable-next-line no-console
          .catch((err) => console.log(err));
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  }, [currentTab]);

  return (
    <Box padding={2}>
      <Box padding={1}>
        <Typography variant="h4">Ajuda</Typography>
      </Box>
      <Divider />
      <Box display="flex" flexDirection="row">
        <Tabs
          orientation="vertical"
          value={currentTab}
          onChange={(_, newValue) => {
            setCurrentTab(newValue);
          }}
          className={classes.tabs}
        >
          <Tab
            value="intro"
            label={
              <Typography variant="body2" color="textPrimary">
                Introdução
              </Typography>
            }
            {...a11yProps(0)}
          />
          <Tab
            value="jornadas"
            label={
              <Typography variant="body2" color="textPrimary">
                Jornadas
              </Typography>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={<Typography variant="body2">Agente Digital</Typography>}
            {...a11yProps(2)}
          />
          <Tab
            label={<Typography variant="body2">WhatsApp</Typography>}
            {...a11yProps(3)}
          />
          <Tab
            label={<Typography variant="body2">Pix</Typography>}
            {...a11yProps(4)}
          />
        </Tabs>
        <Box paddingLeft={2}>
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{currentMd}</ReactMarkdown>
        </Box>
      </Box>
    </Box>
  );
}
